
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'footer-center',
  props: {
    containerClass: {
      default: 'col-lg-12',
      type: String,
    },
  },
  computed: {
    colClass() {
      return `d-flex justify-content-center p-10 w-100 fw-bold fs-6 ${this.containerClass}`;
    },
  },
});
